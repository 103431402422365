

.card {
  position: relative;
  width: 320px;
  height: 480px;
  background: #191919;
  border-radius: 20px;
  overflow: hidden;
}

.card::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background: #754C29;
  transform: skewY(345deg);
  transition: 0.5s;
}
.card2::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background:#f6c38f;
  transform: skewY(345deg);
  transition: 0.5s;
}
.card3::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background:#ddcf8c;
  transform: skewY(345deg);
  transition: 0.5s;
}
.card4::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background:#554750;
  transform: skewY(345deg);
  transition: 0.5s;
}


.card:hover::before {
  top: -70%;
  transform: skewY(390deg);
}

.card::after {
  // content: "ESPRESSO";
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size:60px;
  color: rgba(255, 255, 255, 0.212);
  text-align: center;
  margin-left: 19px;


}

.card .imgBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  z-index: 1;
}
/*
.card .imgBox img {
    max-width: 100%;
    
    transition: .5s;
}

.card:hover .imgBox img {
    max-width: 50%;
      
}
*/
.card .contentBox {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.card .contentBox h3 {
  font-size: 18px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  // margin-top: -35px;
}


.card .contentBox .price {
    font-size: 24px;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
}

.card .contentBox .buy {
  position: relative;
  top: 100px;
  opacity: 0;
  padding: 10px 30px;
//   margin-top: 15px;
  color: #000000;
  text-decoration: none;
  background: #754C29;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.5s;
}
.buy2 {
  background:#f6c38f;

}

.card:hover .contentBox .buy {
  top: 0;
  opacity: 1;
}

.mouse {
  height: 300px;
  width: auto;
}
.buy2{
  font-size: 23px;
  padding: 4px 46px;
}